import type { Client, RequestContext } from "../client";
export interface ExperiencesConfigurationResource {
    IsEnabled: boolean;
}
export class ExperiencesConfigurationRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    get(): Promise<ExperiencesConfigurationResource> {
        return this.client.get<ExperiencesConfigurationResource>(this.requestContext, "~/bff/experiencesconfiguration");
    }
}
