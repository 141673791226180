import type { ICommitCommand } from "./commitCommand";
import type { IHaveVersionProcessResource, IProcessResource, ModifyProcessCommand } from "./deploymentProcessResource";
import type { ResourceWithId } from "./resource";
interface RunbookProcessResourceLinks {
    Self: string;
    Project: string;
    RunbookSnapshotTemplate: string;
    OwnerRunbook: string;
    OwnerRunbookSnapshotTemplate: string;
    OwnerRunbookRunTemplate: string;
}
export interface RunbookProcessResource extends IProcessResource, IHaveVersionProcessResource, ResourceWithId<RunbookProcessResourceLinks> {
    RunbookId: string;
    LastSnapshotId?: string;
    SpaceId: string;
    ProjectId: string;
}
export type ModifyRunbookProcessCommand = RunbookProcessResource & ICommitCommand;
export function isModifyRunbookProcessCommand(command: ModifyProcessCommand): command is ModifyRunbookProcessCommand {
    return "RunbookId" in command;
}
