import type { ActionTemplateParameterResource } from "./actionTemplateParameterResource";
import type { ICommitCommand } from "./commitCommand";
import type { IProcessResource, ModifyProcessCommand } from "./deploymentProcessResource";
import type { IconResource } from "./iconResource";
import type { IId } from "./resource";
export interface BlueprintResource extends IProcessResource, IId {
    Name: string;
    ProjectId: string;
    GitRef: string;
    Slug: string;
    Description?: string;
    Icon?: IconResource;
    Parameters: ActionTemplateParameterResource[];
}
export type ModifyBlueprintCommand = BlueprintResource & ICommitCommand;
export function isModifyBlueprintCommand(command: ModifyProcessCommand): command is ModifyBlueprintCommand {
    return "Parameters" in command;
}
export interface DeleteBlueprintCommand extends ICommitCommand {
    Id: string;
}
export interface NewProcessBlueprint extends ICommitCommand {
    Name: string;
    Description: string;
    ProjectId: string;
}
export interface BlueprintRepositoryResource {
    ProjectId: string;
    Name: string;
    SpaceId: string;
    SpaceName: string;
    StorageDirectory: string;
    DefaultBranch: string;
}
export interface BlueprintBranchHead {
    Commit: string;
}
